import { formatDurationValue } from 'src/lib/baserow/modules/database/utils/duration-1.25.1'

import { BaserowFieldType, BaserowFormulaType } from '../lib/enums'
import numberInputFormatter from '../lib/numberInputFormatter'

export const formatPrimaryFieldValue = (primaryField: any, value: any) => {
  switch (primaryField.type) {
    case BaserowFieldType.NUMBER:
      return numberInputFormatter(
        primaryField.number_separator,
        value,
        primaryField.number_prefix,
        primaryField.number_suffix,
        primaryField.number_decimal_places,
      )
    case BaserowFieldType.TEXT:
    case BaserowFieldType.LONG_TEXT:
    case BaserowFieldType.EMAIL:
    case BaserowFieldType.PHONE_NUMBER:
    case BaserowFieldType.AUTONUMBER:
    case BaserowFieldType.COUNT:
    case BaserowFieldType.UUID:
    case BaserowFieldType.CREATED_ON:
    case BaserowFieldType.LAST_MODIFIED:
    case BaserowFieldType.DATE:
    case BaserowFieldType.URL:
      return value
    case BaserowFieldType.FILE:
      if (value?.length > 0) {
        return value
          .map((file) => file?.visible_name)
          .filter(Boolean)
          .join(', ')
      } else {
        return null
      }
    case BaserowFieldType.BOOLEAN:
      return value ? 'True' : 'False'
    case BaserowFieldType.FORMULA:
      switch (primaryField.formula_type) {
        case BaserowFormulaType.BUTTON:
          return value?.label ?? 'Open'
        case BaserowFormulaType.ARRAY:
        case BaserowFormulaType.TEXT:
        case BaserowFormulaType.DATE_INTERVAL:
        case BaserowFormulaType.DATE:
          if (primaryField.array_formula_type === 'text') {
            return value
              ?.map((lookup) => {
                let parsedLookup
                try {
                  parsedLookup = JSON.parse(lookup.value)
                } catch (e) {
                  parsedLookup = lookup.value
                }
                if (Array.isArray(parsedLookup)) {
                  return parsedLookup
                    .map((obj) => (obj && obj.value ? obj.value : null))
                    .filter(Boolean)
                } else {
                  return parsedLookup ? [parsedLookup] : []
                }
              })
              .flat()
              .join(', ')
          } else if (primaryField.array_formula_type === 'boolean') {
            return value ? 'True' : 'False'
          } else if (primaryField.array_formula_type === 'button') {
            return value?.label ?? 'Open'
          } else if (primaryField.array_formula_type === 'date') {
            return value?.map((lookup) => lookup.value).join(', ')
          } else if (primaryField.array_formula_type) {
            return value?.map((lookup) => lookup.value).join(', ')
          } else {
            if (primaryField.formula_type === 'date') {
              if (primaryField.date_include_time && value) {
                return new Date(value).toLocaleString('en-AU')
              } else if (value) {
                return new Date(value).toLocaleDateString('en-AU')
              } else return null
            } else {
              return String(value)
            }
          }
        case BaserowFieldType.SINGLE_SELECT:
          return value?.value ?? null
        case BaserowFormulaType.DURATION:
          return formatDurationValue(
            Number(value),
            primaryField.duration_format,
          )
        default:
          return String(value)
      }
    case BaserowFieldType.SINGLE_SELECT:
      return value?.value ?? null
    case BaserowFieldType.MULTIPLE_SELECT:
      return value?.map((option) => option?.value).join(', ')
    case BaserowFieldType.LOOKUP:
      switch (primaryField.array_formula_type) {
        case 'text':
        case 'number':
        case 'link':
        case 'char':
        case 'date_interval':
          return value
            ?.map((lookup) => lookup?.value)
            .filter(Boolean)
            .join(', ')
        case 'multiple_select':
        case 'single_select': {
          return value
            .flatMap((lookup) => {
              if (!lookup?.value) return []
              return Array.isArray(lookup.value)
                ? lookup.value.map((item) => item?.value)
                : lookup.value?.value
            })
            .filter(Boolean)
            .join(', ')
        }
        case 'date':
          return value.map((lookup) => lookup?.value).join(', ')
        case 'boolean':
          return value
            .map((boolean) => (boolean?.value ? 'True' : 'False'))
            .join(', ')
        case 'duration':
          return value
            .map((lookup) =>
              lookup?.value
                ? formatDurationValue(
                    lookup.value,
                    primaryField.duration_format,
                  )
                : null,
            )
            .filter(Boolean)
            .join(', ')
        case 'single_file':
          return value
            .map((file) => file?.visible_name)
            .filter(Boolean) // Filter out non-string values (like booleans)
            .join(', ')
        case 'button':
          return value
            .map((button) => button?.value?.label ?? 'Open') // Map to labels, using 'Open' as fallback
            .join(', ')
        default:
          return 'N/A'
      }
    case BaserowFieldType.MULTIPLE_COLLABORATORS: {
      let resultString = ''
      if (value?.length > 0) {
        resultString = value
          .map((collaborator) => collaborator.name)
          .filter(Boolean)
          .join(', ')
      }
      return resultString
    }
    case BaserowFieldType.RATING:
      return value
    case BaserowFieldType.CREATED_BY:
    case BaserowFieldType.LAST_MODIFIED_BY:
      return value?.name ?? 'Unknown User'
    case BaserowFieldType.DURATION:
      return formatDurationValue(Number(value), primaryField.duration_format)
    case BaserowFieldType.ROLLUP: {
      const rollupFunction = primaryField?.rollup_function
      if (primaryField?.formula_type === 'boolean') {
        return `${rollupFunction} (${value ? 'true' : 'false'})`
      } else {
        return `${rollupFunction} (${value})`
      }
    }
    // Field types not yet implemented
    case BaserowFieldType.AI: // WIP
    case BaserowFieldType.PASSWORD: // WIP
    default:
      return 'N/A'
  }
}
